.tooltip-wrapper {
	display: flex;
	align-content: center;
	width: max-content;
	height: max-content;
	position: relative;
	cursor: default;
}

.tooltip-tip {
	position: absolute;
	border-radius: 4px;
	left: 50%;
	transform: translateX(-50%);
	padding: 6px;
	color: white;
	background-color: black;
	font-size: 14px;
	line-height: 1;
	z-index: 100;
	white-space: nowrap;
}

.tooltip-tip::before {
	content: " ";
	left: 50%;
	border: solid transparent;
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-width: 6px;
	margin-left: calc(6px * -1);
}

.tooltip-tip.top {
	top: calc(30px * -1);
}

.tooltip-tip.top::before {
	top: 100%;
	border-top-color: black;
}

.tooltip-tip.right {
	left: calc(100% + 10px);
	top: 50%;
	transform: translateX(0) translateY(-50%);
}

.tooltip-tip.right::before {
	left: calc(6px * -1);
	top: 50%;
	transform: translateX(0) translateY(-50%);
	border-right-color: black;
}

.tooltip-tip.bottom {
	bottom: calc(30px * -1);
}

.tooltip-tip.bottom::before {
	bottom: 100%;
	border-bottom-color: black;
}

.tooltip-tip.left {
	left: auto;
	right: calc(100% + 10px);
	top: 50%;
	transform: translateX(0) translateY(-50%);
}

.tooltip-tip.left::before {
	left: auto;
	right: calc(6px * -2);
	top: 50%;
	transform: translateX(0) translateY(-50%);
	border-left-color: black;
}