.item.message {
	flex-direction: row;
	align-items: center;
	position: relative;
}

.tooltip-wrapper {
	cursor: pointer;
}

.message-summary p {
	line-height: 1.5rem;
}

.actions-menu {
	background-color: white;
	position: absolute;
	right: 0;
	top: 30px;
	right: 26px;
	flex-direction: column;
	color: black;
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 5px;
	z-index: 10;
}

.actions-menu.closed {
	display: none;
}

.actions.menu.opened {
	display: flex;
}

.message-action {
	text-decoration: none;
	cursor: pointer;
	padding: 0.5rem 1rem;
	margin: 0.5rem 0;
	display: flex;
	color: black;
}

.message-action:hover {
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
}

.message-action.delete {
	color: crimson;
}

.message-action.delete:hover {
	background-color: crimson;
	color: white;
}