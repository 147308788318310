.item {
	min-width: 300px;
	display: flex;
	flex-direction: column;
	padding: 0 1rem;
	border: 1px solid rgb(189, 189, 189);
	border-radius: 5px;
	box-sizing: border-box;
	margin: 1rem 0;
}

.item.offline {
	background-color: rgba(224, 180, 180, 0.2);
	border: 1px solid rgb(224, 180, 180);
	color: rgb(145, 32, 32);
}

.item.online {
	background-color: rgba(163, 194, 147, 0.2);
	border: 1px solid rgba(163, 194, 147, 1);
	color: rgb(26, 83, 26);
}

.item-block-info {
	font-size: 0.9rem;
}
