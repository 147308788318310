.login-window {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.login-window h2 {
	color: #555;
}

.login-form {
	height: 220px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.login-input {
	font-family: inherit;
	font-size: inherit;
	border: 1px solid silver;
	padding: 0.5rem;
	border-radius: 0.25rem;
	min-height: 20px;
	vertical-align: middle;
}

.login-input:focus {
	border: 1px solid transparent;
	box-shadow: 0 0 1px 2px cornflowerblue;
	outline: none;
}

.login-error-message {
	color: lightcoral;
	padding: 0.75rem;
}

.login-button {
	background-color: cornflowerblue;
	color: #FFF;
	border: none;
	border-radius: 0.5rem;
	padding: 0.75rem;
	cursor: pointer;
	font-size: 1rem;
}