.nice-dates-popover {
	width: 250px;
}

.nice-dates label p {
	margin: 0;
}

.nice-dates-day_month {
	display: none;
}