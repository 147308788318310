form {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.message-form label {
	width: max-content;
}

.message-form-message {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
}

.message-form-message .form-input {
	margin-bottom: 2px;
}

.message-length {
	font-size: 13px;
	color: #1b1c1d;
}

.form-input {
	font-family: inherit;
	font-size: inherit;
	width: 50%;
	border: 1px solid silver;
	padding: 0.5rem;
	margin: 0.5rem 0 1.25rem;
	border-radius: 0.25rem;
	min-height: 20px;
	vertical-align: middle;
}

.form-input:first-of-type {
	resize: vertical;
}

.form-input:focus {
	border: 1px solid transparent;
	box-shadow: 0 0 1px 2px cornflowerblue;
	outline: none;
}

.message-form-input.characters-limit-warning:focus {
	box-shadow: 0 0 1px 2px rgba(220, 20, 60, 0.75);
}

.form-button {
	text-decoration: none;
	color: #FFF;
	border: none;
	border-radius: 0.5rem;
	padding: 0.75rem;
	cursor: pointer;
	font-size: 1rem;
	margin: 1rem 0;
}

.form-button.submit {
	background-color: mediumseagreen;
}

.form-button.cancel {
	margin-left: 1rem;
	background-color: indianred;
}

.stops-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.stops-list-item {
	margin: 0.5rem 0;
}

.stops-list-item label{
	display: flex;
	align-items: center;
}

.stops-list-item-name {
	margin: 0 0.5rem;
}

.stops-list-item-name.all {
	font-weight: 600;
}

.password-change-input {
	margin: 0.5rem 0;
	width: 250px;
}

.password-validation-error {
	color: crimson;
}

.password-validation-error.active {
	display: flex;
}

.password-validation-error.inactive {
	display: none;
}