.display {
  width: 350px;
}

.display-stop-header {
  display: flex;
  justify-content: center;
  padding: 0.2rem 0;
}

.stop-name {
  align-self: center;
  font-weight: bolder;
  font-size: 1rem;
}

.display-updated-at {
  margin-left: 0.25rem;
  font-size: 0.75rem;
  align-self: flex-end;
}

.display-data {
  display: flex;
  justify-content: center;
  padding: 0.25rem 0;
}

.display-data-items {
	list-style: none;
  width: 100%;
	padding: 0;
  margin: 0.2rem 0;
}

.display-data-item {
	display: grid;
  grid-template-columns: 1fr 5fr 2fr;
	margin: 0.5rem 0;
}

.display-data-item .destination {
  justify-self: start;
}

.display-data-item .time {
  justify-self: end;
}
