.app {
	position: relative;
	overflow: hidden;
}

.app-header {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 0 3rem;
	background-color: #1b1c1d;
	color: #FFF;
	height: 75px;
	overflow: hidden;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 10;
}

.user-menu {
	margin-left: auto;
	min-width: 220px;
	height: 37px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	overflow: hidden;
}

.user-menu span {
	font-weight: 600;
}

.user-menu div:nth-of-type(1) {
	display: flex;
	margin-left: 10px;
	margin-right: auto;
	cursor: pointer;
}

.user-menu button {
	width: 100px;
	background-color: #1b1c1d;
	border: 2px solid #FFF;
	border-radius: 0.25rem;
	font-size: 0.75rem;
	font-weight: 700;
	color: #FFF;
	padding: 0.5rem;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.nav-link {
	color: #FFF;
	margin: 0 1rem;
	padding: 0.5rem;
	text-decoration: none;
	outline: none;
}

.nav-link.active {
	border-bottom: 2px solid #FFF;
}

.main {
	width: 90%;
	margin: 100px auto 5rem;
}